import { Box, Button, IconButton, TextField, useMediaQuery } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React, { CSSProperties, ChangeEvent, KeyboardEvent, useEffect, useMemo, useRef, useState } from "react";
import { Link } from "../../components/Link";
import GoogleButton from "../../components/oidc/GoogleButton";
import {
  LOCAL_STORAGE_AUTH_ATTEMPTS,
  LOCAL_STORAGE_AUTH_REAUTH,
  LOCAL_STORAGE_AUTH_REDIRECT,
} from "../../consts/storageKeys";
import { useAnalyticsContext } from "../../context/AnalyticsContext";
import { useUserContext } from "../../context/UserContext";
import { useCallbackSafeRef } from "../../hooks/useCallbackSafeRef";
import { useGoogleLogin } from "../../hooks/useGoogleLogin";
import { useOurRouter } from "../../hooks/useOurRouter";
import LogoSvg from "../../img/reclaimLogoOnDark.svg";
import { reclaim } from "../../reclaim-api/index";
import { QueryState } from "../../types/query";
import { getSessionStorage, removeLocalStorage, removeSessionStorage } from "../../utils/local-storage";
import { browser } from "../../utils/platform";
import { getUserOnboardingPath } from "../../utils/router";
import LandingLayout from "../LandingLayout";

const useStyles = makeStyles(
  (theme) => ({
    body: {
      margin: theme.spacing(4, 0),
    },
    o365Callout: {
      backgroundColor: "rgba(47, 46, 65, .5)",
      borderRadius: 4,
      margin: theme.spacing(5, "auto", 0),
      maxWidth: 400,
      padding: theme.spacing(2),
      "& a": {
        color: theme.colors.logo.shrimp,
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    gridContainer: {
      backgroundColor: theme.colors.logo.darkness,
      color: theme.colors.white,
      height: "100%",
      padding: theme.spacing(2),
      width: "100vw",
    },
    wrapper: {
      textAlign: "center",
      maxWidth: 600,
      width: "100%",
    },
    logoBtn: {
      display: "block",
    },
    logoSvg: {
      height: "auto",
      marginBottom: theme.spacing(2),
      maxWidth: 200,
      width: "100%",
    },
    googleBtn: {
      margin: theme.spacing(0, "auto", 3),
      maxWidth: 360,
      transform: "scale(1)",
      transition: "transform .2s ease",
      width: "100%",
      "&:hover": {
        transform: "scale(1.02)",
      },
    },
    signupText: {
      margin: theme.spacing(2, 0),
      "& a": {
        color: theme.colors.logo.shrimp,
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    ssoFieldsContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      gap: theme.spacing(),
      [theme.breakpoints.up("sm")]: {
        flexDirection: "row",
      },
    },
    ssoButton: {
      padding: theme.spacing(2, 2.25),
      width: "200",
      [theme.breakpoints.up("sm")]: {
        width: "auto",
      },
    },
    ssoTextFieldInput: {
      borderRadius: 9999,
      backgroundColor: "white",
      width: 300,
      [theme.breakpoints.up("sm")]: {
        width: 280,
      },
      "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.primary.light,
      } as CSSProperties,
    },
  }),
  { index: 2, name: "LoginTemplate" }
);

export type LoginLayoutProps = {
  title: string;
  displayLoginButton: boolean;
  officeNotice?: boolean;
};

export const LoginLayout: React.FC<LoginLayoutProps> = ({ title, displayLoginButton, officeNotice, children }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  /********************/
  /*   custom hooks   */
  /********************/
  const medium = useMediaQuery(theme.breakpoints.down("md"));
  const router = useOurRouter<{ reason: string; state: QueryState }>();
  const [{ user, isAuthenticated, status }] = useUserContext();
  const handleGoogleLogin = useGoogleLogin();
  const {
    state: { sentry },
  } = useAnalyticsContext();

  /********************/
  /*     useState     */
  /********************/
  const [error, setError] = useState<string | undefined>();
  const [ssoEmail, setSsoEmail] = useState<string | undefined>();
  const [isSso, setIsSso] = useState<boolean>(false);
  const ssoInputRef = useRef<HTMLInputElement>(null);

  /********************/
  /* useMemo & consts */
  /********************/
  const urlParams = useMemo(() => new URLSearchParams(browser().isBrowser ? window.location.search : ""), []);

  /********************/
  /*    useCallback   */
  /********************/
  const handleClick = useCallbackSafeRef((provider: string) => {
    if (provider === "google") {
      handleGoogleLogin();
    }
  });

  const handleSSOInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSsoEmail(e.target.value);
  };

  const handleSSOLogin = async () => {
    if (!ssoEmail) {
      setError("No SSO Email provided.");
      return;
    }

    try {
      const urlPath = await reclaim.users.ssoLogin(ssoEmail);

      if (urlPath) {
        reclaim.users.authRedirect(urlPath.replace("/oauth/login/", ""));
        return;
      }
      setError("No associated SSO email found.");
    } catch (error) {
      setError("Error while feching SSO URL.");
      sentry?.captureException("Error while fetching URL", error.message);
    }
  };

  const handleSSOKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      void handleSSOLogin();
    }
  };

  const handleRemoveError = () => {
    void router.replace("/login", undefined, { shallow: true });
    setError(undefined);
  };

  /********************/
  /*    useEffects    */
  /********************/
  useEffect(() => {
    if (!router.query?.reason) return;
    const reason = router.query.reason;

    setError(
      "USER_DISABLED" === reason
        ? "Calendar for this email address is linked to another account. Please sign in using your other Google login."
        : "User not authorized."
    );
  }, [router.query?.reason]);

  // focus when isSso changes to true
  useEffect(() => {
    isSso && ssoInputRef.current?.focus();
  }, [isSso]);

  // Redirect if user is already authenticated
  useEffect(() => {
    if (!router.isReady || !isAuthenticated) return;

    removeLocalStorage(LOCAL_STORAGE_AUTH_REAUTH);
    removeLocalStorage(LOCAL_STORAGE_AUTH_ATTEMPTS);

    const slackTeamId = urlParams.get("slackTeamId");
    const slackUserId = urlParams.get("slackUserId");

    // the check for "://" prevents arbitrary redirection (Open Redirect)
    const oauthRedirect = router.query.state?.redirect;
    const redirect =
      !!oauthRedirect && !oauthRedirect.includes("://")
        ? oauthRedirect
        : getSessionStorage(LOCAL_STORAGE_AUTH_REDIRECT);

    if (redirect) {
      removeSessionStorage(LOCAL_STORAGE_AUTH_REDIRECT);
    }

    // connect slack user
    if (slackUserId && slackTeamId) {
      reclaim.slack.link(slackUserId, slackTeamId).catch((reason) => {
        if (404 === reason.status) {
          sentry?.captureException("Slack user not found, redirecting" + reason);
          reclaim.slack.authRedirect(slackTeamId);
          return;
        } else {
          sentry?.captureException("Failed to link slack user " + reason);
        }
      });
    }

    let pathname = getUserOnboardingPath("welcome");

    if (user?.onboarded) {
      if (redirect) pathname = redirect;
      else if (medium) pathname = "/stats";
      else pathname = "planner";
    }

    void router.push({
      pathname,
      query: router.query,
    });
  }, [isAuthenticated, medium, router, sentry, urlParams, user?.onboarded]);

  return (
    <LandingLayout title={title} loading={["init", "loading"].includes(status) || !!isAuthenticated} hideNav>
      <Grid container className={classes.gridContainer} justifyContent="center" alignItems="center">
        <Box className={classes.wrapper}>
          <IconButton
            className={classes.logoBtn}
            component={Link}
            href={!!user ? "/" : "//reclaim.ai"}
            size="medium"
            aria-label="reclaim.ai"
            disableTouchRipple
            disableRipple
            disableFocusRipple
          >
            <LogoSvg className={classes.logoSvg} />
          </IconButton>
          {children}
          {isSso && (
            <Box className={classes.ssoFieldsContainer}>
              <TextField
                InputProps={{
                  className: classes.ssoTextFieldInput,
                }}
                ref={ssoInputRef}
                type="text"
                value={ssoEmail}
                variant="outlined"
                placeholder="Enter your email"
                onChange={handleSSOInputChange}
                onKeyPress={handleSSOKeyPress}
              />
              <Button variant="contained" color="primary" className={classes.ssoButton} onClick={handleSSOLogin}>
                Log in with SSO
              </Button>
            </Box>
          )}
          {!!displayLoginButton && !isSso && (
            <GoogleButton
              label="Continue with Google"
              onClick={() => {
                handleRemoveError();
                handleClick("google");
              }}
              className={classes.googleBtn}
            />
          )}
          {!!error && (
            <Typography variant="body1" color="error" className={classes.body}>
              {error}&nbsp; If you believe this to be an error, please contact support.
            </Typography>
          )}
          {!!officeNotice && (
            <Typography variant="body2" className={classes.o365Callout}>
              At this time, Reclaim only works on Google Calendar. Interested in Office 365?{" "}
              <Link href="mailto:hello@reclaim.ai?subject=Re:%20Office%20365%20Support%20for%20Reclaim&body=Hi%20Reclaim%20Team!%0D%0A%0D%0AI'm%20an%20Office%20365%20user%20and%20would%20love%20to%20stay%20updated%20when%20Reclaim%20works%20for%20O365%20calendars.%0D%0A%0D%0AThanks!">
                Let&nbsp;us&nbsp;know
              </Link>
            </Typography>
          )}
          {!!displayLoginButton && !isSso && (
            <>
              {router.pathname.startsWith("/login") ? (
                <Typography className={classes.signupText} variant="body2">
                  Don't have an account? <Link href="/signup">Sign up</Link>
                </Typography>
              ) : (
                <Typography className={classes.signupText} variant="body2">
                  Have an account? <Link href="/login">Log in</Link>
                </Typography>
              )}
            </>
          )}
          {!isSso ? (
            <Typography className={classes.signupText} variant="body2">
              <Link
                onClick={() => {
                  handleRemoveError();
                  setIsSso(true);
                }}
              >
                Log in with SSO
              </Link>
            </Typography>
          ) : (
            <Typography className={classes.signupText} variant="body2">
              <Link
                onClick={() => {
                  handleRemoveError();
                  setIsSso(false);
                }}
              >
                Go back
              </Link>
            </Typography>
          )}
        </Box>
      </Grid>
    </LandingLayout>
  );
};
